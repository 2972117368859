table {
  background-color: white;
  font-family: 'HelveticaNeue';
}

th {
  font-size: 12px;
  min-width: 150px;
}

td {
  min-width: 150px;
  max-width: 300px;
  overflow-wrap: break-word;
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 12px;
}
 
::-webkit-scrollbar-track {
  background-color: var(--lightPurple);
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

.form {
  background-color: white;
  border: 1px solid #80808042;
  font-family: 'HelveticaNeue';
  flex-wrap: wrap;
  font-size: 14px;
}

.answer-form {
  flex-direction: column;
}

.text-answer {
  border-bottom: 2px solid;
  margin-top: 2em !important;
  width: 70% !important;
}

.sb-pagination {
  transform: scale(0.8);
}

.sb-pagination li a{
  color: var(--secondary);
}

.sb-pagination .active span {
  background-color: var(--primary) !important;
}

.sb-pagination .page-link:focus, .sb-pagination .page-link:hover {
  box-shadow:none;
  color: var(--primary);
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.7);
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.visual-question {
  font-family: 'HelveticaNeue';
  font-weight: 900;
}

.visual-cont {
  padding: 2em !important;
  background-color: #ffffffad;
  border-radius: 10px;
  border: 1px solid #0000002b;
}
.exceedRange {
  outline: 3px solid red;
  outline-offset: -3px;
  background-color: #ff595912 !important;
}

.CellWithComment{
  position:relative;
}

.CellComment{
  display:none;
  position:absolute; 
  z-index:100;
  border:1px;
  background-color:white;
  border-style:solid;
  border-width:1px;
  border-color:red;
  padding:3px;
  color:red; 
  top:20px; 
  left:20px;
}

.CellWithComment:hover span.CellComment{
  display:block;
}