section {
  display: flex;
  height: 100vh;
  justify-content: center;
  background-color: var(--lightGrey);
  font-family: 'HelveticaNeue';
}
body {
  scroll-behavior: unset !important;
}
.heavy_weight {
  font-weight: 900 !important;
}
.light_weight {
  font-weight: 400 !important;
}
/* ########################################################### */
nav {
  width: 99vw;
  display: flex;
  background-color: var(--lightGrey);
  z-index: 9;
  font-family: 'HelveticaNeue';
  overflow-x: hidden;
}
nav a {
  text-decoration: none;
}
.logo_land {
  width: 100px;
  height: 85px;
}
.logo_land img {
  width: 35%;
  margin: 1rem 2rem;
}
.list_cont {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  align-items: center;
}
.list_item {
  font-weight: 900;
  font-size: 1em;
  color: var(--primary);
  cursor: pointer;
}
.list_item:hover {
  color: var(--secondary);
  background-color: hsla(240, 19%, 39%, 0.033);
  padding: 0.5em;
}
#reg_but {
  padding: 0.4em 1em;
  background-color: var(--primary);
  border-radius: 3px;
  color: white;
}
#down_but {
  padding: 0.4em 1em;
  background-color: var(--secondary);
  border-radius: 3px;
  color: white;
}
.lang {
  display: flex;
  align-items: center;
  padding-right: 1em;
  font-weight: 900;
  cursor: pointer;
}
#amh {
  padding: 0.5em;
  border-right: 1px solid var(--primary);
}
#eng {
  padding: 0.5em;
  /* opacity: 0.3; */
}
.netela {
  font-size: 1.5em;
  cursor: default !important;
}
.netela:hover {
  color: initial;
  background-color: initial;
  padding: 0;
}
.options {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.menu {
  display: none;
  align-items: center;
  font-size: 2em;
}
.menu-bar {
  display: none;
  position: absolute;
  right: -100%;
}
/* ############################################################################ */
#first {
  background: linear-gradient(1800deg, var(--primary) 60%, var(--lightGrey) 50%);
}
#download_app {
  position: absolute;
    bottom: 2em;
    margin: 3em;
    padding: 0.5em 1em;
    border-radius: 5px;
    cursor: pointer;
    transform: scale(0.07);
    color: var(--lightGrey);
}
#download_app a {
  text-decoration: none;
  color: initial;
}
.headline {
  position: absolute;
  top: 39%;
  font-family: 'Teko';
  font-size: 5em;
  text-align: center;
  text-transform: uppercase;
}
.headline p {
  margin: -10px;
}
#p_2 {
  color: white;
}
#second {
  background-color: var(--primary);
  display: flex;
  height: 85vh;
}
.main {
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
}
#second img {
  width: 15%;
}
#head_logo {
  margin: 1em;
}
#title {
  font-family: 'Teko';
  font-weight: 900;
  font-size: 4em;
  /* text-decoration: underline; */
  color: var(--lightGrey);
  text-decoration-thickness: from-font;
  margin: 0.5em 1em;
  /* text-decoration-line: underline; */
  text-underline-offset: 5px;
}
#intro {
  font-size: 22px;
  width: 70%;
  font-weight: 100;
  color: var(--lightGrey);
}
#third {
  background-color: var(--secondary);
  padding: 4em 2em;
  display: block;
  text-align: center;
}
#third-and-half {
  background-color: var(--lightGrey);
  padding: 7em 0em;
  display: flex;
  justify-content: start;
  color: var(--secondary);
  height: fit-content;
}
#third-and-half .sub_title, #third-and-half .ficon, #third-and-half .desc, #third-and-half > #title{
  color: var(--secondary) !important;
}
#third-and-half .arrow {
  color: #acacac !important;
}
.offer  {
  padding: 2em;
  margin-bottom: 6em;
}
.offer .offer-title {
  font-family: 'Teko';
  text-align: start;
  font-weight: 900;
  font-size: 5em;
  color: #282829;
}
.offer img {
  transform: scale(0.8);
}
.offer .offer-content-text {
  width: 50%;
  font-size: 1.2em;
}
.offer .offer-content {
  display: flex;
  justify-content: space-between;
  padding: 0 2.5em;
}
.offer-text {
  min-width: 16%;
  height: 100vh;
  padding: 2em;
}
#offer-text-main {
  position: absolute;
  width: 15%;
  height: 250vh;
  padding: 2em;
}
#offer-text-main p {
  font-family: 'Teko';
  font-size: 11em;
  word-break: break-all;
  line-height: 0.8em;
  font-weight: 900;
  color: #282829;
  opacity: 0.2;
  position: sticky;
  top: 0.5em;
}

.offer-container li{
  list-style-type: square;
}
.offer-container sup{
  font-size: 0.5em;
}
.desc {
  opacity: 0.8;
}
.steps {
  margin-top: 7em;
  display: flex;
  justify-content: space-evenly;
}
.step {
  width: 15%;
  color: var(--lightGrey);
}
.ficon {
  font-size: 3.5em;
  margin-bottom: 0.5em;
}
.sub_title {
  font-family: 'Teko';
  font-weight: 400;
  font-size: 1.8em;
}
.arrow {
  color: rgba(255, 255, 255, 0.5);
  font-size: 2.5em;
}
#fourth {
  background-color: var(--lightGrey);
  padding: 4em 2em;
  display: block;
  text-align: center;
}
.reasons {
  margin-top: 7em;
  display: flex;
  justify-content: space-evenly;
  color: var(--secondary) !important;
}
.reason {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reason img {
  width: 60%;
}
.pic_cont {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#fifth {
  background-color: var(--yellow);
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#fifth p {
  font-family: 'Teko';
  font-size: 4em;
  font-weight: 900;
  margin: 0;
}
#fifth .arrow {
  font-size: 3em;
  color: var(--secondary) !important;
  margin-left: 1em;
  margin-top: -0.2em;
  cursor: pointer;
  transition: 'color' 50ms ease;
}
#fifth .arrow:hover {
  color: var(--lightGrey);
}
footer {
  position: relative;
  font-family: "HelveticaNeue";
  text-align: center;
  background-color: var(--secondary);
  color: white;
  padding-bottom: 3em;
}
footer h1 {
  font-weight: 900;
  padding: 0.2em;
}
footer h6 {
  font-weight: 400;
}
footer a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  position: relative;
}
.contact_info {
  display: flex;
  justify-content:space-evenly
}

/* ################################################################################## */
/* ############################################################################################################## */

@media screen and (max-width: 700px) {
  nav {
    position: unset !important;
  }
  .options {
    display: none;
  }
  .menu {
    display: flex;
  }
  .logo_land {
    margin-left: -2em;
  }
  .menu-bar {
    text-align: center;
    position: absolute;
    display: none;
    flex-direction: column;
    width: 50vw;
    background: white;
    padding: 1em;
    right: -100%;
    height: 100vh;
    box-shadow: -2px 0px 3px #00000026;
    border-start-start-radius: 3px;
    border-start-end-radius: 10px;
    transition: all 200ms ease;
    z-index: 3;
  }
  .show-menu-bar {
    right: 0;
    display: flex;
  }
  .menu-bar .lang {
    padding: 3em 1em;
    justify-content: center;
  }
  nav {
    justify-content: space-between;
    padding: 2.5em;
    position: relative;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  /* ------------------- */
  #first {
    background: linear-gradient(1800deg, var(--primary) 71%, var(--lightGrey) 50%);
  }
  .headline {
    top: 42%;
    font-size: 4em;
  }
  #head_logo {
    margin: 0 2em 2em;
  }
  #title {
    margin: 0;
    font-size: 3em;
  }
  #intro {
    font-size: 15px;
  }
  /* ###### */
  #third {
    height: fit-content;
  }
  #third-and-half {
    height: fit-content;
  }
  #offer-text-main {
    display: none;
  }
  .offer-text {
    display: none;
  }
  .offer-content-text {
    width: 100% !important;
  }
  .offer img {
    display: none;
  }
  .offer-title {
    font-size: 4em !important;
  }
  .steps {
    flex-direction: column;
    margin-top: 3em;
    width: 100%;  
  }
  .step {
    width: 100%;
  }
  #third .arrow {
    transform: rotate(90deg);
  }
  #third-and-half .arrow {
    transform: rotate(90deg);
  }
  /* ######### */
  #fourth {
    height: fit-content;
  }
  .reasons {
    flex-direction: column;
    margin-top: 3em;
    width: 100%;
  }
  .reason {
    width: 100%;
  }
  /* ########### */
  #fifth {
    padding: 2em;
    line-height: 3em;
  }
  #contact-us {
    padding: 3em 0 0;
  }
  /* ------------------- */
  .cards_prices {
    flex-direction: column;
  }
  .price_card {
    width: 100% !important;
    margin-bottom: 3em;
  }
  .title {
    width: 50% !important;
  }
  /* -------------------- */
  .reg_sec {
    justify-content: center !important;
    padding-top: 3em;
    padding-right: 0 !important;
    display: flex;
  }
  .side_text {
    display: none;
  }
  .reg_form {
    width: 80% !important;
  }
  .title {
    width: 70%;
  }
  .title_box {
    font-size: 2em;
    font-weight: 900;
    color: var(--primary);
    border: 2px solid var(--primary);
    padding: 0.1em 1em;
    border-radius: 20px;
    background-color: rgb(80 80 117 / 5%);
    letter-spacing: 2px;
    text-align: center;
  }

  .input_form {
    display: grid;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 2em;
    width: 100%;
  }
  .contact_logo {
    display: none;
  }
  .contact_socials {
    display: flex;
    justify-content: space-evenly;
    padding: 2em;
  }
  .contact_emails {
    padding-top: 2em;
  }
}
