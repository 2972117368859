.reg_sec {
    justify-content: flex-end;
    padding-top: 3em;
    display: flex;
    padding-right: 15em;
    height: 88vh !important;
    background-size: 75%;
    transition: all 200ms ease;
  }
  .reg_form {
    width: 40%;
    z-index: 2;
  }
  .reg_header_text {
    font-size: 2.5em;
    font-weight: 900;
    text-align: center;
    padding: 0.5em;
    color: var(--primary);
    letter-spacing: 1px;
  }
  form button {
    background-color: var(--primary);
    color: white;
    border: 0;
    font-size: 1em;
    font-weight: 400;
    padding: 0.5em 0.75em;
    border-radius: 3px;
  }
  .input_form {
    display: grid;
    font-size: 1em;
    font-weight: 400;
    width: 100%;
  }
  .fill_form {
    display: flex;
    flex-wrap: wrap;
    font-family: "HelveticaNeue";
  }
  input {
    width: 100%;
    border: 1px solid rgb(80 80 117 / 50%);
  }
  input:focus-visible {
    border: 2px solid var(--primary) !important;
  }
  #fn,
  #nm {
    width: 45%;
  }
  .side_text {
    padding-left: 1em;
    font-family: 'Teko';
    font-weight: 900;
    padding-top: 1em;
    font-size: 7em;
    max-width: 70%;
    line-height: 0.8em;
    padding-right: 1em;
  }
  .confirm {
    background-color: #ffc107;
    padding: 0.7em;
    text-align: center;
    color: #282829;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: 20px 19px 7px 2px #ffc10761;
  }
  .confirm span {
    float: right;
    margin-right: 0.5em;
    cursor: pointer;
  }
  .error-confirm {
    background-color: var(--DangerRed);
    padding: 0.7em;
    text-align: center;
    color: white;
    font-weight: 400;
    border-radius: 5px;
  }
  .error-confirm span {
    float: right;
    margin-right: 0.5em;
    cursor: pointer;
  }
  .form-item {
    width: 100%;
    margin-right: 0.5em;
  }
  .bank_choice img{
    width: 30px;
    background-color: white;
    width: 100%;
    padding: 0.4em;
    border-radius: 5px;
    cursor: pointer;
  }
  .bank_choice:hover {
    border-radius: 5px;
    background-color: var(--lightGrey);
  }
  .form-item .form-label {
    margin-bottom: 0.2em;
  }
  .bank_selected {
    border-radius: 5px;
    outline: 2px solid;
  }
  .cbe {
    outline-color: blueviolet;
  }
  .dashen {
    outline-color: darkblue;
  }
  .radio_price {
    font-size: 12px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 1em;
  }
  .reg_price {
    font-weight: 700;
    font-family: 'Teko';
    font-size: 1.5em;
    font-style: italic;
  }
  .reg_price p {
    margin: 0;
  }