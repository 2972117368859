/* ########################################################### */
.price_section {
    padding-top: 2em;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    height: fit-content
  }
  .title {
    width: 100%;
    position: sticky;
    top: 0;
    background-color: var(--lightGrey);
  }
  .title_box {
    font-size: 2.5em;
    font-weight: 900;
    color: var(--primary);
    border: 2px solid var(--primary);
    padding: 0.2em 1em;
    border-radius: 20px;
    background-color: rgb(80 80 117 / 5%);
    letter-spacing: 2px;
    text-align: center;
  }
  .toggle_price {
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    margin-top: -2em;
    margin-bottom: 1em;
  }
  #mth {
    padding: 0em 1em;
    opacity: 0.4;
  }
  #yr {
    padding: 0em 1em;
    opacity: 0.4;
  }
  .active_toggle_mth {
    opacity: 1 !important;
    border-right: 2px solid var(--primary);
  }
  .active_toggle_yr {
    opacity: 1 !important;
    border-left: 2px solid var(--primary);
  }
  .white {
    background-color: #f8f8f8;
    color: var(--secondary);
  }
  .black {
    background-color: var(--secondary);
    color: #f8f8f8;
  }
  .purple {
    background-color: var(--primary);
    color: #f8f8f8;
  }
  .yellow {
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
    color: var(--lightGrey);
  }
  .op-3 {
    opacity: 0.4;
  }
  .cards_prices {
    width: 80%;
    padding: 1em 10em;
    display: flex;
    justify-content: center;
    margin-bottom: 5em;
  }
  .price_card {
    width: 30%;
    padding: 1em;
    box-shadow: 4px 4px 7px 0px rgb(0 0 0 / 16%);
    border-radius: 10px;
  }
  .price_card span {
    height: 30px;
    width: 30px;
    display: block;
    border-radius: 50%;
    background-color: var(--lightGrey);
    box-shadow: inset 4px 4px 7px rgb(0 0 0 / 16%);
  }
  .card_content {
    text-align: center;
    font-family: "HelveticaNeue";
    margin: 0.5em 0;
  }
  .card_title {
    font-size: 2.5em;
    font-family: 'Teko';
    text-transform: uppercase;
  }
  .card_list {
    text-align: left;
  }
  .card_desc {
    padding: 0em 5em;
    width: 50%;
  }
  .card_desc .title_card_dec {
    font-family: 'Teko';
    font-size: 3em;
    line-height: 1em;
  }
  /* ################################################################################### */


  @media screen and (max-width: 700px) {
    .cards_prices {
      padding: 0;
    }
    .title {
      width: 100% !important;
    }
    .toggle_price {
      margin-top: 0;
    }
    .card_desc {
      padding: 0em 1em;
      width: 100%;
      text-align: center;
    }
  }