.login-screen {
  padding: 2em 4em;
  height: 100vh;
}
.login-screen img {
  transform: scale(0.8);
}
.login-tag {
  margin-bottom: 1rem;
}
.version-tag {
  position: absolute;
  bottom: 2em;
  left: 0px;
  right: 0px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  opacity: 0.6;
}
.error-notif {
  display: flex;
  padding: 0.5rem;
  background-color: var(--DangerRed);
  border-radius: 3px;
  color: var(--lightGrey) !important;
  align-items: center;
  margin-bottom: 1rem;
}
.rem-class {
  font-size: 12px;
  font-family: 'HelveticaNeue';
}
.rem-class .form-select-sm {
  font-size: 12px !important;
}
.rem-class .form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.rem-class .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(81, 81, 118, 0.25) !important;
}
.rem-class .form-check-input::before {
  content: none;
}