.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid var(--primary);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transform: scale(0.7);
}
.checkbox-wrapper label {
  display: flex;
}

input:checked {
  background-color: var(--primary);
  position: relative;
}

input:checked::before {
  content: '✓';
  font-size: 1.2em;
  color: #fff;
  position: absolute;
  right: 4px;
  top: -4px;
  font-weight: 900;
}

.checkbox-wrapper input[type="checkbox"]:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:disabled + span {
  color: #c0c0c0;
}
